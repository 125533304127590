const PortfolioList = [
	{
		title: 'Slackbot',
		languages: ['javascript'],
		image: ['images/jahnellybot.png'],
		github: 'https://github.com/jmadarang87/SlackBot',
		link: '',
	},
	{
		title: 'CRUD: Plant Application',
		languages: ['MySQL, Express, React, Node'],
		image: ['images/plant_app.png'],
		github: 'https://github.com/jmadarang87/CRUD-plant-application',
		link: '',
	},
	{
		title: 'OOP: Phrase Hunter',
		languages: ['javascript'],
		image: ['images/phrase_hunter.png'],
		github: 'https://github.com/jmadarang87/OOP-Game-Show-App',
		link: 'https://phrase-hunter-jm.herokuapp.com/',
	},
	{
		title: 'OOP: Four in a Row',
		languages: ['javascript'],
		image: ['images/four_in_a_row.png'],
		github: 'https://github.com/jmadarang87/OOP-four-in-a-row-game',
		link: 'https://four-in-a-row-jm.herokuapp.com/',
	},
	{
		title: 'Mockup Website: Dribble.com',
		languages: ['html', 'css', 'javascript'],
		image: ['images/mockup_dribble1.png', 'images/mockup_dribble2.png'],
		github: 'https://github.com/jmadarang87/Lynette-s-Garden.git',
		link: 'https://dribbble-jm.herokuapp.com/',
	},
	{
		title: 'Clock',
		languages: ['html', 'css', 'javascript'],
		image: ['images/clock.jpg'],
		github: 'https://github.com/jmadarang87/Clock',
		link: 'https://clock-jm.herokuapp.com/',
	},
	{
		title: 'Calculator',
		languages: ['css', 'html', 'javascript'],
		image: ['images/calculator.jpg'],
		github: 'https://github.com/jmadarang87/Jahnels-Caculator',
		link: 'https://calculator-jm.herokuapp.com/',
	},

	{
		title: 'Interactive Form with Regex Validation',
		languages: ['html', 'javascript'],
		image: [
			'images/form-validation-preview-1.png',
			'images/form-validation-preview-2.png',
			'images/form-validation-preview-3.png',
		],
		github: 'https://github.com/jmadarang87/interactive-form',
		link: 'https://interactive-form-jm.herokuapp.com/',
	},
	{
		title: 'Random Quote Generator',
		languages: ['html', 'css', 'javascript'],
		image: [
			'images/random-quote-generator1.png',
			'images/random-quote-generator2.png',
			'images/random-quote-generator3.png',
		],
		github: 'https://github.com/jmadarang87/a-random-quote-generator',
		link: 'https://random-quote-generator-jm.herokuapp.com/',
	},
];

export default PortfolioList;
